export const primitiveNumbers = {
  /// from design system
  /// numbers
  pNum4: '4px',
  pNum8: '8px',
  pNum12: '12px',
  pNum16: '16px',
  pNum24: '24px',
  pNum32: '32px',
  pNum48: '48px',
  pNum64: '64px',
  pNum96: '96px',
  pNum128: '128px',
  pNum192: '192px',
  pNum256: '256px',
  pNum384: '384px',
  pNum512: '512px'
}
