import { Box, CircularProgress } from '@mui/material'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { useAuth } from 'store/user/authContext'
import { ExternalSignupView } from './externalSignUpView'
import { SignInView } from './signInView'
import { UserPanel } from './userPanel'

export const ProfilePanelView = () => {
  const { isUserLoading, user } = useAuth()

  return (
    <>
      {isUserLoading && (
        <ChildSpacerV>
          <Box alignItems="center" display="flex">
            <CircularProgress />{' '}
            <span style={{ marginLeft: 16 }}>Signing in...</span>
          </Box>
        </ChildSpacerV>
      )}
      {!isUserLoading && (
        <>
          {user.isSignedIn && !user.hasAccount && <ExternalSignupView />}
          {!user.isSignedIn && <SignInView />}
          {user.isAuthenticated && <UserPanel />}
        </>
      )}
    </>
  )
}
