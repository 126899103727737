import { Typography } from '@mui/material'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { CSTooltip } from 'Components/visual/csTooltip'
import Crown from 'Components/visual/crown.svg'

function ProfileHeaderWinnerCrown({ entry }) {
  if (!entry.wins || entry.wins <= 0) {
    return null
  }

  return (
    <CSTooltip id={'profile-crown'} text={`${entry.wins}-time winner`}>
      <img
        src={Crown}
        alt="crown"
        style={{
          width: 24,
          height: 24,
          transform: 'rotateZ(20deg)',
          position: 'absolute',
          color: 'gold',
        }}
      />
    </CSTooltip>
  )
}

export const UserPanelViewHeader = ({ team }) => {
  return (
    <ChildSpacerVAll>
      <Typography
        variant="h2"
        style={{ textAlign: 'center', position: 'relative' }}
      >
        {team.name} <ProfileHeaderWinnerCrown entry={team} />
      </Typography>
    </ChildSpacerVAll>
  )
}
