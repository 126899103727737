import { semanticMedia } from 'styles/semanticMedia'

export const Text = ({ children, color, nowrap, style }) => {
  return (
    <span
      style={{
        color: semanticMedia[color] || semanticMedia.contrastMedium,
        whiteSpace: nowrap ? 'nowrap' : 'normal',
        ...style,
      }}
    >
      {children}
    </span>
  )
}
