import { Button, Paper, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import { GameReviewCardInfo } from './gameReviewCardInfo'

const PREFIX = 'GameCard'

const classes = {
  button: `${PREFIX}-button`,
}

const Root = styled('div')({
  [`& .${classes.button}`]: {
    transition: 'all 0.2s',
    '&:hover': {
      '.game-card-info': {
        backgroundColor: semanticSurface.surfaceLayerMedium,
      },
    },
  },
})

export const GameReviewCard = ({ game }) => {
  const isMobile = useMediaQuery(query.mobile)
  const navigate = useNavigate()
  return (
    <Root
      style={{
        position: 'relative',
        display: 'flex',
        flex: 1,
        border: game.selected ? `2px solid ${colors.theme}` : 'none',
        borderRadius: 4,
        margin: game.selected ? '-1 0 0 -1' : '0',
      }}
    >
      <Button
        className={classes.button}
        style={{
          display: 'flex',
          flex: 1,
          padding: 0,
          margin: 0,
          textTransform: 'none',
          textAlign: 'left',
        }}
        onClick={() => navigate('/game/' + game.slug)}
      >
        <Paper
          style={{
            display: 'flex',
            position: 'relative',
            flex: 1,
            height: 220,
            overflow: 'hidden',
          }}
        >
          <GameReviewCardInfo game={game} />
          <span
            style={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: colors.theme,
            }}
          >
            {game.selected && (
              <span className="material-symbols-outlined">check_circle</span>
            )}
          </span>
        </Paper>
      </Button>
    </Root>
  )
}
