import { Text } from 'Components/visual/text'

const styles = {
  maxWidth: '600px',
}

export const JoinIngress = () => {
  return (
    <Text style={styles.ingress}>
      Critical Showdown is about predicting critics' review scores of video
      games. You can play in Picks mode and Predictions mode and a round lasts
      for 3 months in both modes.
    </Text>
  )
}
