import { Button } from '@mui/material'
import { GameName } from 'Components/game/gameName'
import { RoundCardButton } from 'Components/game/RoundCardButton'
import { WatchlistButton } from 'Components/game/watchlistButton'
import { VBox } from 'Components/layout'
import { ChildSpacerH, ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { DarkList } from 'Components/visual/darkList'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { ReleaseDateFormatter } from 'pages/home/profilePanel/releaseDateFormatter'
import { useNavigate } from 'react-router-dom'
import { PredictionBadgeMini } from '../visual/scoreBadge/predictionBadgeMini'

export const UserWatchlist = ({ watchlist, isLoading }) => {
  const navigate = useNavigate()

  const columns = [
    {
      ColComponent: ({ colData }) => {
        return (
          <VBox align="center-left">
            <GameName game={colData.entry} />
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <ReleaseDateFormatter releaseDate={colData.entry.released} />
              <ChildSpacerH
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <PredictionBadgeMini
                  score={colData.entry.prediction}
                  tooltipId={colData.entry.id}
                />
                {colData.entry.metacritic > 0 ? (
                  <ScoreBadge
                    game={colData.entry}
                    lowestScored={colData.entry.lowest}
                    text="Score"
                  />
                ) : (
                  <span>Not released</span>
                )}
              </ChildSpacerH>
            </div>
            <WatchlistButton
              game={colData.entry}
              ButtonComponent={RoundCardButton}
            />
          </VBox>
        )
      },
    },
  ]

  return (
    <>
      <DarkList
        isLoading={isLoading}
        stackOnMobile={false}
        hoverEffect="scaleFX"
        onRowClick={(colData) => {
          navigate(`/game/${colData.slug}`)
        }}
        emptyText={
          <>
            <ChildSpacerVAll>
              <div>
                You have no games in your watchlist.
                <br />
                <br />
                Add games to your watchlist on the <strong>Games</strong> page
                to keep track of some of your favorites either for the game or
                because you're just looking forward to play them.
              </div>
            </ChildSpacerVAll>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/games')
              }}
              style={{ width: '100%' }}
            >
              Go to Games
            </Button>
          </>
        }
        list={watchlist}
        columns={columns}
      ></DarkList>
    </>
  )
}
