import { useMediaQuery } from '@mui/material'
import { ConfirmDropDialog } from 'Components/panes/gameView/confirmDropDialog'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { useRounds } from 'store/game/roundContext'
import { useUINotifs } from 'store/game/uiNotificationsContext'
import { usePickGame } from 'store/game/userPickGameContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { semanticMedia } from 'styles/semanticMedia'
import { isWithin } from 'util/date'
import { isGameLocked, isGameReleased } from 'util/gameLogic'

export const PickDropButton = ({ style, game, ButtonComponent, readonly }) => {
  const { gameLimit } = useGameFilter()
  const { onPickGame, onDropGame, savingGameId } = usePickGame()
  const { selectedGames } = useGames()
  const { mainRounds } = useRounds()
  const [gameToDrop, setGameToDrop] = useState(null)
  const { user } = useAuth()

  const navigate = useNavigate()
  const { setGameActionSnackState } = useUINotifs()

  const isMobile = useMediaQuery(query.mobile)

  const awaitingAction = savingGameId
  const saving = savingGameId === game.id

  const isReleased = !!isGameReleased(game)
  const tooLate = !!(isGameLocked(game) || isReleased)

  if (!ButtonComponent) {
    console.error('ButtonComponent is required')
    return <>Missing button component</>
  }

  const shouldShowButton =
    !readonly &&
    game.metacritic === null &&
    isWithin(
      game.released,
      mainRounds.current.startDate,
      mainRounds.next.endDate
    )
  const shouldShowDropButton = game.selected && game.dropAllowed
  const shouldShowPickButton =
    !game.selected && selectedGames.length < gameLimit && !tooLate

  const handleClickDrop = async (gameToDrop) => {
    if (gameToDrop.dropAllowed && gameToDrop.dropAfterReleaseAllowed) {
      setGameToDrop(gameToDrop)
    } else {
      await onDropGame(gameToDrop, user.team.id)
    }
  }

  return (
    <>
      <ConfirmDropDialog
        gameToDrop={gameToDrop}
        setGameToDrop={setGameToDrop}
      />

      {tooLate && !isReleased && (
        <span
          style={{
            ...style,
            fontSize: '12px',
            bottom: 12,
            right: 16,
            color: semanticMedia.contrastLow,
          }}
        >
          Too close to release
        </span>
      )}

      {shouldShowButton &&
        ((shouldShowDropButton && (
          <ButtonComponent
            label="Drop"
            icon="close"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleClickDrop(game)
            }}
            saving={saving}
            disabled={awaitingAction}
            color={colors.theme}
            style={{ ...style }}
          />
        )) ||
          (shouldShowPickButton && (
            <ButtonComponent
              label="Pick"
              icon="add"
              onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()
                if (!user.isAuthenticated) {
                  if (isMobile) {
                    navigate('/sign-in')
                  } else {
                    setGameActionSnackState({
                      isOpen: true,
                      text: `Sign in or create your profile first to pick games.`,
                      severity: 'warning',
                    })
                  }
                } else {
                  await onPickGame(game, user.team.id)
                }
              }}
              saving={saving}
              disabled={awaitingAction}
              style={{ ...style, minWidth: 0 }}
              color={colors.theme}
            />
          )))}
    </>
  )
}
