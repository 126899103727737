import { Box, CircularProgress } from '@mui/material'
import { ScrollRestoration, useParams } from 'react-router-dom'
import { apiEndpoints } from 'showdown-api/api'
import useCachedData from 'util/useCachedData'
import { UserPanelViewHeader } from '../profilePanel/userPanelViewHeader'
import { InspectTeamPredictionsView } from '../sidebar/inspectTeamPredictionsView'

export const TeamPredictionsView = () => {
  const { teamUserId } = useParams()

  const [isLoadingTeam, team] = useCachedData(
    apiEndpoints.team + '/' + teamUserId,
    null,
    [teamUserId]
  )

  return (
    <>
      <ScrollRestoration />
      {isLoadingTeam && (
        <Box justifyContent="center" alignItems="center" display="flex">
          <CircularProgress />{' '}
          <span style={{ marginLeft: 16 }}>Loading team...</span>
        </Box>
      )}
      {!isLoadingTeam && (
        <>
          <UserPanelViewHeader team={team} />
          <InspectTeamPredictionsView
            team={team}
            teamUserId={teamUserId}
            isLoadingTeam={isLoadingTeam}
          />
        </>
      )}
    </>
  )
}
