import { Box } from '@mui/material'
import { CardGrid } from 'Components/game/cardGrid'
import { Cols } from 'Components/layout/cols'
import { Breakpoint } from 'react-socks'
import { useGames } from 'store/game/gameContext'
import { useGameFilter } from 'store/game/gameFilterContext'
import { query } from 'styles/mediaQueries'
import { GameFilter } from './gameFilter'
import { GameFilterControls } from './gameFilterControls'
import { PicksSummary } from 'pages/home/profilePanel/picksSummary'
import { useAuth } from 'store/user/authContext'

export const GameFilterView = () => {
  const { filteredSelectedGames } = useGameFilter()
  const { isLoadingAvailableGames } = useGames()
  const { user } = useAuth()

  return (
    <>
      <Breakpoint customQuery={query.desktopLDown}>
        <GameFilterControls
          isLoadingGames={isLoadingAvailableGames}
          filteredSelectedGames={filteredSelectedGames}
        />
        <Box p={3}></Box>
        {user.isAuthenticated && (
          <>
            <PicksSummary placedOnProfile={false} />
            <Box p={5} />
          </>
        )}{' '}
        <CardGrid
          isLoading={isLoadingAvailableGames}
          list={filteredSelectedGames}
          animateDirection={-1}
        />
      </Breakpoint>
      <Breakpoint customQuery={query.desktopLUp}>
        <Cols set="auto 350px" gap={96}>
          <div>
            <GameFilterControls
              isLoadingGames={isLoadingAvailableGames}
              filteredSelectedGames={filteredSelectedGames}
            />
            {user.isAuthenticated && (
              <>
                <PicksSummary placedOnProfile={false} />
                <Box p={5} />
              </>
            )}
            <CardGrid
              isLoading={isLoadingAvailableGames}
              list={filteredSelectedGames}
              animateDirection={-1}
            />
          </div>
          <GameFilter />
        </Cols>
      </Breakpoint>
    </>
  )
}
