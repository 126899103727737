import { fontSize } from 'styles/fontSize'
import { semanticMedia } from 'styles/semanticMedia'

export const LeaderboardPlace = ({ place }) => {
  return (
    <span
      style={{
        fontSize: fontSize.large,
        color: semanticMedia.accentTheme,
      }}
    >
      {place}
    </span>
  )
}
