import { HBox } from 'Components/layout'
import { ChildSpacerH } from 'Components/layout/ChildSpacer'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { useEffect, useState } from 'react'
import { buildBadgeCounts } from 'util/gameLogic'

export const BadgeCounters = ({ gameList, badges }) => {
  const [badgeCounters, setBadgeCounters] = useState(
    !badges
      ? {
          iron: 0,
          bronze: 0,
          silver: 0,
          gold: 0,
          ultra: 0,
        }
      : badges
  )

  useEffect(() => {
    setBadgeCounters(!badges ? buildBadgeCounts(gameList) : badges)
  }, [gameList, badges])

  return (
    <HBox style={{ padding: '0 25%', justifyContent: 'center' }}>
      <ChildSpacerH>
        <ScoreBadge
          score={90}
          customValue={badgeCounters.ultra}
          variant="VERTICAL"
          text="Platinum"
        />
        <ScoreBadge
          score={80}
          customValue={badgeCounters.gold}
          variant="VERTICAL"
          text="Gold"
        />
        <ScoreBadge
          score={70}
          customValue={badgeCounters.silver}
          variant="VERTICAL"
          text="Silver"
        />
        <ScoreBadge
          score={60}
          customValue={badgeCounters.bronze}
          variant="VERTICAL"
          text="Bronze"
        />
        <ScoreBadge
          score={50}
          customValue={badgeCounters.iron}
          variant="VERTICAL"
          text="Iron"
        />
      </ChildSpacerH>
    </HBox>
  )
}
