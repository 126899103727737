import { MobileFooter } from 'Components/panes/mobileFooter'
import { Breakpoint } from 'react-socks'
import { query } from 'styles/mediaQueries'
import { UserPanelView } from './userPanelView'

export const UserPanel = () => {
  return (
    <>
      <UserPanelView />
      <Breakpoint customQuery={query.tabletDown}>
        <MobileFooter />
      </Breakpoint>
    </>
  )
}
