import { Box, Button, CircularProgress, useMediaQuery } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTheme } from '@mui/material/styles'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { UserWatchlist } from 'Components/panes/userWatchlist'
import * as React from 'react'
import { useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useGames } from 'store/game/gameContext'
import { useRounds } from 'store/game/roundContext'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import { sortByProperty } from 'util/sort'
import { InspectTeamPredictionsView } from '../sidebar/inspectTeamPredictionsView'
import { HBox } from './../../../Components/layout/HBox'
import { VBox } from './../../../Components/layout/VBox'
import { UserPanelViewHeader } from './userPanelViewHeader'
import { UserPicksSummary } from './userPicksSummary'
import { YourGamesList } from './yourGamesList'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export const UserPanelView = () => {
  const { user, isUserLoading, signOut } = useAuth()

  const isMobile = useMediaQuery(query.mobile)

  const { selectedRound, isGameReleasedInRound } = useRounds()

  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  /// From UserPredictionsList.js. Maybe move to a custom hook?
  const {
    isLoadingUserGames,
    availableGames,
    watchedGames,
    isLoadingWatchedGames,
  } = useGames()

  const userWatchlist = useMemo(() => {
    let gamesMap = {}
    availableGames?.forEach((game) => {
      gamesMap[game.id] = game
    })

    return watchedGames
      .map((watchedGame) => {
        const game = gamesMap[watchedGame.id]

        if (!isGameReleasedInRound(game, selectedRound)) {
          return false
        }

        return {
          ...game,
          watched: watchedGame.watched,
        }
      })
      .filter((game) => game !== false)
      .sort((a, b) => sortByProperty(a, b, 'prediction', 'DESC'))
      .slice(0, 50)
  }, [availableGames, watchedGames, selectedRound, isGameReleasedInRound])

  return (
    <>
      <UserPanelViewHeader team={user.team} />
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="user panel tabs"
          style={{ maxWidth: '600px' }}
        >
          <Tab label="Picks" {...a11yProps(0)} />
          <Tab label="Predictions" {...a11yProps(1)} />
          <Tab label="Watchlist" {...a11yProps(1)} />
        </Tabs>
        <div
          style={{
            width: '100%',
            height: '1px',
            marginTop: '-1px',
            backgroundColor: semanticSurface.surfaceLayerHigh,
          }}
        ></div>
      </div>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Box p={4} />
          <UserPicksSummary user={user} selectedRound={selectedRound} />
          <ChildSpacerVAll>
            {isLoadingUserGames && (
              <>
                <Box p={2} />
                <HBox align="center">
                  <VBox align="center">
                    <CircularProgress />
                    <Box p={1} />
                    Loading your game picks
                  </VBox>
                </HBox>
              </>
            )}
            {!isLoadingUserGames && (
              <>
                <YourGamesList />
              </>
            )}
          </ChildSpacerVAll>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Box p={4} />
          <InspectTeamPredictionsView
            team={user.team}
            teamUserId={user.id}
            isLoadingTeam={isUserLoading}
            selectedRound={selectedRound}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <Box p={4} />
          <UserWatchlist
            watchlist={userWatchlist}
            isLoading={isLoadingWatchedGames}
          />
        </TabPanel>
      </SwipeableViews>

      {isMobile && (
        <Button variant="outlined" style={{ width: '100%' }} onClick={signOut}>
          Sign out
        </Button>
      )}
    </>
  )
}
