import { ChildSpacerHAll, ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { colors } from '../../styles/colors'

export const HowToPlayPredictionsPanel = ({ gameLimitOverride }) => {
  const tipsList = [
    {
      primary: 'Predict the exact review score of as many games as possible',
      /* secondary: `Pick and drop games from your profile during a quarter of a year.`, */
    },
    {
      primary: 'Score more points the closer you were when reviews drop',
      /* secondary: 'When a game gets metacritic reviews you get points.', */
    },
    {
      primary: 'Strive for #1 on the Predictions leaderboard',
      /* secondary: `Hopefully your profile has the most points at the end of a quarter.`, */
    },
  ]

  return (
    <ChildSpacerVAll>
      <h3>Predictions mode</h3>
      {tipsList.map((tip, index) => {
        return (
          <ChildSpacerHAll
            key={index}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 40, color: colors.theme }}
            >
              star
            </span>
            <span style={{ fontSize: '14px', verticalAlign: 'middle' }}>
              {tip.primary}
            </span>
          </ChildSpacerHAll>
        )
      })}
    </ChildSpacerVAll>
  )
}
