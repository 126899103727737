import * as React from 'react'
import { apiEndpoints } from 'showdown-api/api'
import useCrudData from 'util/useCrudData'
import { useRounds } from './roundContext'
import { findRealFromIds } from 'util/list'
import { sortByProperty, sortByReleasedAndName } from 'util/sort'
import {
  buildPredictionGrade,
  calcPredictionPoints,
  isGameReleased,
} from 'util/gameLogic'
import { PREDICTIONS_SORT_OPTIONS } from './gameFilterContext'

const PredictionsContext = React.createContext()

function PredictionsProvider({ children }) {
  const { selectedRound } = useRounds()

  // Load predictions for games in the selected round
  const [
    isLoadingRoundGamePredictions,
    roundGamePredictions,
    createRoundGamePrediction,
    readRoundGamePrediction,
    updateRoundGamePrediction,
    deleteRoundGamePrediction,
    setRoundGamePrediction,
  ] = useCrudData(
    apiEndpoints.predictions_by_round + '?roundId=' + selectedRound.id,
    [], // initial value
    null, // observe
    null // transform
  )

  function createInspectedUser(
    team,
    teamUserId,
    availableGames,
    showNotReleased,
    showNoPoints,
    selectedRound,
    predictionsSortMode,
    isGameReleasedInRound
  ) {
    if (!team) {
      return null
    }

    const realGames = findRealFromIds(
      availableGames,
      Object.keys(team.predictions)
    ).found

    const realGamesWithinRound = realGames.filter((game) =>
      isGameReleasedInRound(game, selectedRound)
    )

    const sortedGames = realGamesWithinRound.sort((a, b) =>
      sortByReleasedAndName(a, b, 'DESC')
    )

    let totalPoints = 0

    const predictions = sortedGames.map((game) => {
      let predictionScore = team.predictions[game.id]

      if (typeof predictionScore === 'string') {
        predictionScore = parseInt(predictionScore)
      }

      const diff = Math.abs(game.metacritic - predictionScore)
      const grade = buildPredictionGrade(diff)
      const points = calcPredictionPoints(diff)

      totalPoints += points

      return {
        game,
        predictionResult: { predictionScore, diff, grade, points },
      }
    })

    const predictionsFiltered = predictions.filter((prediction) => {
      if (!isGameReleased(prediction.game) && !showNotReleased) {
        return false
      }

      if (
        isGameReleased(prediction.game) &&
        !prediction.predictionResult.points &&
        !showNoPoints
      ) {
        return false
      }

      return true
    })

    let predictionsSorted = predictionsFiltered
    if (predictionsSortMode.id === PREDICTIONS_SORT_OPTIONS.points.id) {
      predictionsSorted = predictionsFiltered.sort((a, b) =>
        sortByProperty(a.predictionResult, b.predictionResult, 'points', 'DESC')
      )
    }

    const teamWithPredictions = {
      ...team,

      totalPoints,
      predictions: predictionsSorted,
    }

    return { team: teamWithPredictions, userId: teamUserId }
  }

  const predictionsContext = {
    isLoadingRoundGamePredictions,
    roundGamePredictions,
    createRoundGamePrediction,
    readRoundGamePrediction,
    updateRoundGamePrediction,
    deleteRoundGamePrediction,
    setRoundGamePrediction,

    createInspectedUser,
  }

  return (
    <PredictionsContext.Provider value={predictionsContext}>
      {children}
    </PredictionsContext.Provider>
  )
}

function usePredictions() {
  const context = React.useContext(PredictionsContext)
  if (!context) {
    throw new Error(`usePredictions must be used within a PredictionsProvider`)
  }
  return context
}

export { PredictionsProvider, usePredictions }
