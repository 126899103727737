import { Box, useMediaQuery } from '@mui/material'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'

export const HeroMessage = ({ padding, children }) => {
  const isMobile = useMediaQuery(query.mobile)

  return (
    <>
      <div
        style={{
          border: `2px solid ${semanticSurface.surfaceThemeLow}`,
          borderRadius: '4px',
          padding: padding ?? isMobile ? '16px' : '48px',
          color: colors.white,
        }}
      >
        {children}
      </div>
      <Box p={1} />
    </>
  )
}
