import React, { useEffect, useMemo, useState } from 'react'
import { browserState } from 'store/browserState'
import { useGames } from 'store/game/gameContext'
import { useAuth } from 'store/user/authContext'
import {
  SCORE_UNLIKELY_THRESHOLD,
  getGamePredictionScore,
  getGamePredictions,
  isDropAfterReleaseAllowed,
  isDropAllowed,
  isGameReleased,
  isGameReviewed,
  isGameSelected,
} from 'util/gameLogic'
import { sortByProperty, sortByReleasedAndName } from 'util/sort'
import { usePredictions } from './predictionsContext'
import { useRounds } from './roundContext'

const GameFilterContext = React.createContext()

const GAME_SORT_OPTIONS = {
  releaseDate: 'Release',
  likelyToScore: 'Likeliness',
  score: 'Score',
  yourPrediction: 'Your prediction',
  communityPrediction: 'Community prediction',
}

export const PREDICTIONS_SORT_OPTIONS = {
  release: { label: 'Release date', id: 'release' },
  points: { label: 'Points', id: 'points' },
}

function getEmptyFilter() {
  return {
    activeFilterVersion: 2,
    releaseStatus: 'UNRELEASED', // 'UNRELEASED', 'RELEASED', 'REVIEWED', 'NOT_PREDICTED'
    includeUnpopularGames: false,
    involvedCompanies: false,
    category: {
      fullGame: false,
      remake: false,
      remaster: false,
      port: false,
    },
    score: {
      platinum: false,
      gold: false,
      silver: false,
      bronze: false,
      iron: false,
    },
    platforms: {
      PS5: false,
      PS4: false,
      XSX: false,
      XONE: false,
      Switch: false,
    },
    genreVisualNovel: false,
    genreIndie: false,
  }
}

function getSavedBrowserFilter() {
  let gameFilter = browserState.getGameFilter()

  if (
    !gameFilter ||
    gameFilter.activeFilterVersion !== getEmptyFilter().activeFilterVersion
  ) {
    gameFilter = getEmptyFilter()
    browserState.saveGameFilter(gameFilter)
  }

  return gameFilter
}

function GameFilterContextProvider({ ...props }) {
  const { children } = props

  const {
    availableGames,
    selectedGames,
    selectedGamesOutsideRound,
    calcGameProfilePoints,
    watchedGames,
  } = useGames()
  const { selectedRound, isGameReleasedInRound } = useRounds()
  const { user } = useAuth()
  const { roundGamePredictions } = usePredictions()

  // Search filter
  const [search, setSearch] = useState('')
  const [gameFilter, setGameFilterInternal] = useState(getSavedBrowserFilter())
  const [gameSortMode, setGameSortMode] = useState(
    GAME_SORT_OPTIONS.releaseDate
  )
  const [filteredGames, setFilteredGames] = useState([])

  // Predictions filters
  const [showNotReleased, setShowNotReleased] = useState(false)
  const [showNoPoints, setShowNoPoints] = useState(false)
  const [predictionsSortMode, setPredictionsSortMode] = useState(
    PREDICTIONS_SORT_OPTIONS.release
  )

  const toggleSortMode = () => {
    setPredictionsSortMode(
      predictionsSortMode.id === PREDICTIONS_SORT_OPTIONS.release.id
        ? PREDICTIONS_SORT_OPTIONS.points
        : PREDICTIONS_SORT_OPTIONS.release
    )
  }

  const clearGameFilter = () => {
    setGameFilter(getEmptyFilter())
    setSearch('')
  }

  const setGameFilter = (newFilter) => {
    setGameFilterInternal(newFilter)
    browserState.saveGameFilter(newFilter)
  }

  useEffect(() => {
    setFilteredGames(
      availableGames?.filter((game) => {
        let include = true

        // Disregard games outside of round filter
        if (!isGameReleasedInRound(game, selectedRound)) {
          return false
        }

        switch (gameFilter.releaseStatus) {
          case 'UNRELEASED':
            include = !isGameReleased(game)
            break
          case 'RELEASED':
            include = isGameReleased(game)
            break
          case 'REVIEWED':
            include = isGameReviewed(game)
            break
          case 'NOT_PREDICTED':
            include = !isGameReleased(game) && game.yourPrediction === null
            break
          default:
            include = false
        }

        if (include && !gameFilter.includeUnpopularGames) {
          include = game.profilePoints >= SCORE_UNLIKELY_THRESHOLD
        }

        if (
          include &&
          (gameFilter.category.fullGame ||
            gameFilter.category.remake ||
            gameFilter.category.remaster ||
            gameFilter.category.port)
        ) {
          include =
            (game.category === 'Full game' && gameFilter.category.fullGame) ||
            (game.category === 'Remake' && gameFilter.category.remake) ||
            (game.category === 'Remaster' && gameFilter.category.remaster) ||
            (game.category === 'Port' && gameFilter.category.port)
        }

        if (
          include &&
          (gameFilter.score.platinum ||
            gameFilter.score.gold ||
            gameFilter.score.silver ||
            gameFilter.score.bronze ||
            gameFilter.score.iron)
        ) {
          include =
            (gameFilter.score.platinum && game.metacritic >= 90) ||
            (gameFilter.score.gold && game.metacritic >= 80) ||
            (gameFilter.score.silver && game.metacritic >= 70) ||
            (gameFilter.score.bronze && game.metacritic >= 60) ||
            (gameFilter.score.iron &&
              game.metacritic > 0 &&
              game.metacritic < 60)
        }

        if (
          include &&
          (gameFilter.platforms.PS5 ||
            gameFilter.platforms.PS4 ||
            gameFilter.platforms.XSX ||
            gameFilter.platforms.XONE ||
            gameFilter.platforms.Switch)
        ) {
          include =
            (gameFilter.platforms.PS5 &&
              game.platforms.find((platform) => platform.name === 'PS5')) ||
            (gameFilter.platforms.PS4 &&
              game.platforms.find((platform) => platform.name === 'PS4')) ||
            (gameFilter.platforms.XSX &&
              game.platforms.find(
                (platform) => platform.name === 'Series X'
              )) ||
            (gameFilter.platforms.XONE &&
              game.platforms.find((platform) => platform.name === 'XONE')) ||
            (gameFilter.platforms.Switch &&
              game.platforms.find((platform) => platform.name === 'Switch'))
        }

        if (include && gameFilter.involvedCompanies) {
          include = game?.involved_companies?.length > 0 ?? false
        }

        if (include && gameFilter.media) {
          include = game.videos || game.screenshots || game.cover
        }

        if (include && gameFilter.genreVisualNovel) {
          include =
            game.genres?.find(
              (genre) =>
                genre.name.toLowerCase() === 'Visual novel'.toLowerCase()
            ) === undefined ?? true
        }
        if (include && gameFilter.genreIndie) {
          include =
            game.genres?.find(
              (genre) => genre.name.toLowerCase() === 'Indie'.toLowerCase()
            ) === undefined ?? true
        }

        if (include && search.length > 0) {
          include = game.name?.toLowerCase().includes(search.toLowerCase())
        }

        return include
      })
    )
  }, [
    setFilteredGames,
    user,
    search,
    availableGames,
    selectedRound,
    gameFilter,
    isGameReleasedInRound,
  ])

  const filteredSelectedGames = useMemo(() => {
    const result =
      filteredGames?.map((game) => {
        return {
          ...game,
          profilePoints: calcGameProfilePoints(game),
          selected: isGameSelected(
            game,
            selectedGames,
            selectedGamesOutsideRound
          ),
          predictions: getGamePredictions(roundGamePredictions, game.id),
          yourPrediction: getGamePredictionScore(user, game),
          watched: watchedGames.find(
            (watchedGame) => watchedGame.id === game.id
          ),
          dropAllowed: isDropAllowed(game),
          dropAfterReleaseAllowed: isDropAfterReleaseAllowed(game),
        }
      }) ?? []

    const sortedResult = result.sort((a, b) => {
      if (gameSortMode === GAME_SORT_OPTIONS.releaseDate) {
        return sortByReleasedAndName(
          a,
          b,
          gameFilter.releaseStatus !== 'UNRELEASED' ? 'DESC' : 'ASC'
        )
      } else if (gameSortMode === GAME_SORT_OPTIONS.likelyToScore) {
        return sortByProperty(a, b, 'profilePoints', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.score) {
        return sortByProperty(a, b, 'metacritic', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.yourPrediction) {
        return sortByProperty(a, b, 'yourPrediction', 'DESC')
      } else if (gameSortMode === GAME_SORT_OPTIONS.communityPrediction) {
        return sortByProperty(a.predictions, b.predictions, 'average', 'DESC')
      } else {
        return sortByReleasedAndName(a, b)
      }
    })
    return sortedResult
  }, [
    selectedGames,
    selectedGamesOutsideRound,
    filteredGames,
    user,
    watchedGames,
    calcGameProfilePoints,
    gameSortMode,
    gameFilter.releaseStatus,
    roundGamePredictions,
  ])

  const filterGameContext = {
    filteredGames,
    setFilteredGames,
    filteredSelectedGames,

    gameFilter,
    setGameFilter,
    clearGameFilter,

    setSearch,
    gameSortMode,
    setGameSortMode,
    GAME_SORT_OPTIONS,
    gameLimit: 10,

    showNotReleased,
    setShowNotReleased,
    showNoPoints,
    setShowNoPoints,
    predictionsSortMode,
    setPredictionsSortMode,
    toggleSortMode,
  }

  return (
    <GameFilterContext.Provider value={filterGameContext}>
      {children}
    </GameFilterContext.Provider>
  )
}

function useGameFilter() {
  const context = React.useContext(GameFilterContext)
  if (!context) {
    throw new Error(
      `useGameFilter must be used within a GameFilterContextProvider`
    )
  }
  return context
}

export { GameFilterContextProvider, useGameFilter }
