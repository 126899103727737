import { Box, CircularProgress } from '@mui/material'
import { useMemo } from 'react'
import { apiEndpoints } from 'showdown-api/api'
import { useGames } from 'store/game/gameContext'
import { findRealFromIds } from 'util/list'
import useCachedData from 'util/useCachedData'
import { UserPanelReadonlyView } from './userPanelReadonlyView'

export const InspectTeamView = ({ userId, selectedRound }) => {
  const gameLimit = 10

  const [isLoadingTeam, team] = useCachedData(
    apiEndpoints.team + '/' + userId,
    null,
    [userId]
  )

  const { isLoadingAvailableGames, availableGames } = useGames()

  const user = useMemo(() => {
    if (!team) {
      return null
    }

    if (!(selectedRound?.id in team.rounds)) {
      return { team, id: team.userId }
    }

    const teamWithRealGames = {
      ...team,
      rounds: {
        ...team.rounds,
        [selectedRound.id]: findRealFromIds(
          availableGames,
          team.rounds[selectedRound.id]
        ).found,
      },
    }

    return { team: teamWithRealGames, id: team.userId }
  }, [team, availableGames, selectedRound])

  return (
    <>
      {(isLoadingTeam || isLoadingAvailableGames) && (
        <Box justifyContent="center" alignItems="center" display="flex">
          <CircularProgress />{' '}
          <span style={{ marginLeft: 16 }}>Loading team...</span>
        </Box>
      )}
      {!isLoadingTeam && !isLoadingAvailableGames && user && (
        <UserPanelReadonlyView
          inspectedUser={user}
          selectedRound={selectedRound}
          inspectedUserSelectedGames={user.team.rounds[selectedRound.id]}
          gameLimit={gameLimit}
        />
      )}
    </>
  )
}
