import { Box, useMediaQuery } from '@mui/material'
import { HBox } from 'Components/layout'
import {
  ChildSpacerH,
  ChildSpacerHAll,
  ChildSpacerV,
} from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { ScoreBadge } from 'Components/visual/scoreBadge/scoreBadge'
import { Text } from 'Components/visual/text'
import { ReleaseDate } from 'pages/home/profilePanel/releaseDate'
import { useMemo } from 'react'
import { usePredictions } from 'store/game/predictionsContext'
import { useAuth } from 'store/user/authContext'
import { colors } from 'styles/colors'
import { query } from 'styles/mediaQueries'
import { semanticSurface } from 'styles/semanticSurface'
import {
  getGamePredictionScore,
  getGamePredictions,
  isGameReleased,
} from 'util/gameLogic'
import { PredictionBadgeMini } from '../visual/scoreBadge/predictionBadgeMini'
import { GameName } from './gameName'
import { GameProfile } from './gameProfile'
export const GameReviewCardInfo = ({ game }) => {
  const isMobile = useMediaQuery(query.mobile)

  const { user } = useAuth()
  const userPrediction = useMemo(
    () => getGamePredictionScore(user, game),
    [user, game]
  )

  const { roundGamePredictions } = usePredictions()
  const gamePredictions = useMemo(() => {
    return getGamePredictions(roundGamePredictions, game.id)
  }, [roundGamePredictions, game])

  // Show badge if game is unreleased or is released and the logged in user made a prediction
  let showUserPredictionBadge =
    user.isAuthenticated && isGameReleased(game) && game.metacritic

  return (
    <div
      className="game-card-info"
      style={{
        padding: '16px',
        backdropFilter: isMobile ? 'none' : 'blur(10px)',
        backgroundColor: semanticSurface.surfaceLayerLow,
        position: 'absolute',
        color: colors.white,
        bottom: 0,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Box>
        <div style={{ fontSize: '16px', paddingRight: '32px' }}>
          <GameName game={game} />{' '}
        </div>
        {game?.category !== 'Full game' && (
          <SubtleText>
            {game.category === 'Full game' || game.category === null
              ? ''
              : game.category}
          </SubtleText>
        )}
        <Box p={2} />
        <HBox>
          <ChildSpacerV>
            {game.metacritic && (
              <HBox>
                <ScoreBadge
                  variant="HORIZONTAL"
                  game={game}
                  text="Current review score. May change until the end of the round as more reviews drop."
                />
              </HBox>
            )}
            {gamePredictions?.average > 0 && (
              <ChildSpacerH>
                <HBox align="center" style={{ position: 'relative' }}>
                  <span
                    className="material-symbols-outlined"
                    style={{ opacity: '0.2' }}
                  >
                    Public
                  </span>
                  <span
                    style={{ color: colors.disabled, position: 'absolute' }}
                  >
                    {Math.round(gamePredictions?.average)}
                  </span>
                </HBox>
                <Text>Average prediction</Text>
              </ChildSpacerH>
            )}
            {showUserPredictionBadge && (
              <>
                {!userPrediction && <Text>You did not predict this game.</Text>}
                {userPrediction > 0 && (
                  <ChildSpacerH>
                    <PredictionBadgeMini score={userPrediction} />{' '}
                    <Text>Your prediction</Text>
                  </ChildSpacerH>
                )}
              </>
            )}
          </ChildSpacerV>
        </HBox>
        <ChildSpacerHAll
          style={{
            whiteSpace: 'nowrap',
            fontSize: '12px',
            position: 'absolute',
            left: '16px',
            bottom: '16px',
          }}
        >
          <GameProfile game={game} />
          <div>{game.tba ? 'No date' : <ReleaseDate game={game} />}</div>
        </ChildSpacerHAll>
      </Box>
    </div>
  )
}
