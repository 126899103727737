// Material UI components
import { Alert, AlertTitle, Box, Button, Collapse } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { startResetPassword } from 'Components/firebase/firebase'
import { ErrorSummary } from 'Components/form/errorSummary'
import { IconButton } from 'Components/interactive'
import { HBox, VBox } from 'Components/layout'
import { ChildSpacerV } from 'Components/layout/ChildSpacer'
import { Footer } from 'Components/panes/footer'
import { Text } from 'Components/visual/text'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Breakpoint } from 'react-socks'
import { apiEndpoints } from 'showdown-api/api'
import { apiPost } from 'showdown-api/rest'
import { useAuth } from 'store/user/authContext'
import { query } from 'styles/mediaQueries'

export const SignInView = ({ navigateBack = true }) => {
  const { signInUser } = useAuth()

  const navigate = useNavigate()

  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
    errors: {},
    loading: false,
    forgotEmailWarning: false,
  })

  const [passwordEmailSent, setPasswordEmailSent] = useState(false)

  const handleChange = (event) => {
    setLoginState({
      ...loginState,
      [event.target.name]: event.target.value,
      forgotEmailWarning: false,
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoginState({ ...loginState, loading: true })
    const userData = {
      email: loginState.email,
      password: loginState.password,
    }

    await apiPost(apiEndpoints.login, userData)
      .then((data) => {
        signInUser(data.idToken).then((result) => {
          if (result === true) {
            console.log('Logged in')
            setLoginState({
              ...loginState,
              errors: {},
              loading: false,
            })
            if (navigateBack) {
              navigate(-1)
            }
          } else {
            setLoginState({
              ...loginState,
              errors: result,
              loading: false,
            })
          }
        })
      })
      .catch((error) => {
        if (error) {
          console.error(error)
          setLoginState({
            ...loginState,
            errors: (error && error?.message) ?? { message: error.message },
            loading: false,
          })
        }
      })
  }

  const onSendPasswordEmail = () => {
    if (loginState.email) {
      setLoginState({
        ...loginState,
        forgotEmailWarning: false,
      })
      startResetPassword(loginState.email)
      setPasswordEmailSent(true)
    } else {
      setLoginState({
        ...loginState,
        forgotEmailWarning: true,
      })
    }
  }

  return (
    <ChildSpacerV>
      <Typography variant="h2">Sign in</Typography>
      <Text>To play picks and predictions modes.</Text>

      <form noValidate>
        <ChildSpacerV>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            helperText={loginState.errors.email}
            error={loginState.errors.email ? true : false}
            onChange={handleChange}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            helperText={loginState.errors.password}
            error={loginState.errors.password ? true : false}
            onChange={handleChange}
          />
          {passwordEmailSent ? (
            <>
              <Collapse in={passwordEmailSent}>
                <Box p={1} />
                <Alert severity="success">
                  <AlertTitle>Reset password email sent</AlertTitle>
                  <span>Check your inbox/spam folder.</span>
                </Alert>
              </Collapse>
            </>
          ) : (
            <VBox flexType="hug">
              <HBox align="center-left">
                <Button
                  variant="text"
                  onClick={onSendPasswordEmail}
                  style={{ marginRight: '8px' }}
                >
                  Forgot password?{' '}
                </Button>
              </HBox>
              {loginState.forgotEmailWarning ? (
                <>
                  <Box p={1} />
                  <Collapse in={loginState.forgotEmailWarning}>
                    <Box p={1} />
                    <Alert severity="warning">
                      <AlertTitle>Forgot password</AlertTitle>

                      <span>Enter your email in the email field above</span>
                    </Alert>
                  </Collapse>
                </>
              ) : (
                ''
              )}
            </VBox>
          )}
          <ErrorSummary label="Could not login" errors={loginState.errors} />
          <IconButton
            label="Sign in"
            onClick={handleSubmit}
            loading={loginState.loading}
            disabled={
              loginState.loading || !loginState.email || !loginState.password
            }
            size="large"
          />
          <Button
            variant="outlined"
            onClick={() => {
              navigate('/sign-up')
            }}
          >
            Join free to play
          </Button>
        </ChildSpacerV>
      </form>
      <Breakpoint customQuery={query.tabletDown}>
        <Footer />
      </Breakpoint>
    </ChildSpacerV>
  )
}
