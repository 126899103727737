import { CircularProgress } from '@mui/material'
import { VBox } from 'Components/layout'
import { useGames } from 'store/game/gameContext'
import { TeamPredictionsListView } from './teamPredictionsListView'
import { usePredictions } from 'store/game/predictionsContext'
import { useMemo } from 'react'
import { useGameFilter } from 'store/game/gameFilterContext'
import { useRounds } from 'store/game/roundContext'

export const InspectTeamPredictionsView = ({
  team,
  teamUserId,
  isLoadingTeam,
}) => {
  const { isLoadingAvailableGames } = useGames()

  const { selectedRound, isGameReleasedInRound } = useRounds()
  const { availableGames } = useGames()
  const { showNotReleased, showNoPoints, predictionsSortMode } = useGameFilter()

  const { createInspectedUser } = usePredictions()

  const inspectedUser = useMemo(
    () =>
      createInspectedUser(
        team,
        teamUserId,
        availableGames,
        showNotReleased,
        showNoPoints,
        selectedRound,
        predictionsSortMode,
        isGameReleasedInRound
      ),
    [
      createInspectedUser,
      team,
      teamUserId,
      availableGames,
      showNotReleased,
      showNoPoints,
      selectedRound,
      predictionsSortMode,
      isGameReleasedInRound,
    ]
  )

  return (
    <>
      {(isLoadingTeam || isLoadingAvailableGames) && (
        <VBox align="center">
          <CircularProgress />
        </VBox>
      )}
      {!isLoadingTeam && (
        <>
          <TeamPredictionsListView inspectedUser={inspectedUser} />
        </>
      )}
    </>
  )
}
