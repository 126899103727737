import { Button } from '@mui/material'

export const PredictionsSortButton = ({
  predictionsSortMode,
  toggleSortMode,
}) => {
  return (
    <Button
      style={{ minWidth: '160px' }}
      variant="outlined"
      onClick={toggleSortMode}
    >
      <span
        className="material-symbols-outlined"
        style={{ marginLeft: '-4px' }}
      >
        expand_more
      </span>{' '}
      {predictionsSortMode.label}
    </Button>
  )
}
