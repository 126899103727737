import { ExternalLink } from 'Components/interactive/ExternalLink'
import { Text } from 'Components/visual/text'

export const ShowyInfo = () => {
  return (
    <>
      <h3>Stay up to date with Showy the discord bot!</h3>
      <Text>
        Showy will always tell you when reviews come in, games are added or
        release dates changes as soon as it happens.
      </Text>
      <ExternalLink
        url="https://discord.gg/KrrCYAx9vZ"
        label="Visit Showy on our Discord server"
      />
    </>
  )
}
