import { primitiveNumbers } from "./primitiveNumbers";

export const fontSize = {
  // Base
  small: primitiveNumbers.pNum12,
  medium: primitiveNumbers.pNum16,
  large: primitiveNumbers.pNum24,
  xlarge: primitiveNumbers.pNum32,
  xxlarge: primitiveNumbers.pNum48,
  xxxlarge: primitiveNumbers.pNum64
}
