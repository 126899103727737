import { Checkbox, FormControlLabel } from '@mui/material'
import { VBox } from 'Components/layout'
import { ChildSpacerVAll } from 'Components/layout/ChildSpacer'
import { SubtleText } from 'Components/visual/SubtleText'
import { ScoreLegend } from '../leaderboards/scoreLegend'
import { InspectPredictionBadge } from './inspectPredictionBadge'
import { PredictionsSortButton } from './predictionsSortButton'
import { useGameFilter } from 'store/game/gameFilterContext'

export const TeamPredictionsListView = ({ inspectedUser }) => {
  const {
    showNotReleased,
    setShowNotReleased,
    showNoPoints,
    setShowNoPoints,
    predictionsSortMode,
    toggleSortMode,
  } = useGameFilter()

  if (!inspectedUser?.team?.predictions) {
    console.error('inspectedUser.team.predictions is null')
    return null
  }

  return (
    <div>
      <ChildSpacerVAll>
        <VBox align="center">
          <div
            style={{
              fontSize: '32px',
              lineHeight: '1.2em',
            }}
          >
            {inspectedUser.team.totalPoints}p
          </div>
          <SubtleText text="Total prediction points"></SubtleText>
        </VBox>

        <h3>Predictions breakdown</h3>
        <ScoreLegend />
        <VBox>
          <span style={{ marginRight: 16 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showNotReleased}
                  onChange={() => setShowNotReleased(!showNotReleased)}
                  name="showNotReleased"
                />
              }
              label="Show not released"
            />
          </span>
          <FormControlLabel
            control={
              <Checkbox
                checked={showNoPoints}
                onChange={() => setShowNoPoints(!showNoPoints)}
                name="showNoPoints"
              />
            }
            label="Show not scored"
          />
        </VBox>
        <PredictionsSortButton
          predictionsSortMode={predictionsSortMode}
          toggleSortMode={toggleSortMode}
        />

        {inspectedUser?.team?.predictions?.map((prediction) => (
          <InspectPredictionBadge
            key={prediction.game.id}
            game={prediction.game}
            predictionResult={prediction.predictionResult}
          />
        ))}

        {inspectedUser?.team?.predictions?.length === 0 && (
          <div>No games shown. Try selecting another filter.</div>
        )}
      </ChildSpacerVAll>
    </div>
  )
}
