import { themeOpacity } from 'styles/primitiveColors'
import { semanticInteractive } from 'styles/semanticInteractive'

export const Bar = ({ progress, height }) => {
  const actualHeight = height ?? 4

  return (
    <div
      style={{
        height: actualHeight,
        width: '100%',
        backgroundColor: themeOpacity.pWhiteOpacity8,
      }}
    >
      <div
        style={{
          height: actualHeight,
          width: `${progress > 100 ? 100 : progress}%`,
          backgroundColor: semanticInteractive.fillThemeDefault,
        }}
      ></div>
    </div>
  )
}
